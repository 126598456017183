var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "取料申请",
          "left-arrow": "",
          "safe-area-inset-top": "",
          border: false
        },
        on: { "click-left": _vm.leftClick }
      }),
      _c("div", { staticClass: "orderInfo" }, [
        _c("div", { staticClass: "orderbg" }, [
          _c("div", { staticClass: "orderTop" }, [
            _c("div", { staticClass: "orderTopText" }, [
              _c("h2", [_vm._v("提交成功")]),
              _c("div", { staticClass: "auditTime" }, [
                _vm._v(
                  " 申请已进入审核(审核时间为10:00-17:00)，提交付款凭证截图可加快审核哦！ "
                ),
                _c("span", { on: { click: _vm.goSeeSee } }, [
                  _vm._v("去上传>>")
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "orderBtm" }, [
            _c("div", { staticClass: "oddNumbers flex" }, [
              _c("div", { staticClass: "odd flexleft" }, [_vm._v("提料单号")]),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.orderInfo.openNo))
              ])
            ]),
            _c("div", { staticClass: "oddTime flex" }, [
              _c("div", { staticClass: "odd flexleft" }, [_vm._v("提交时间:")]),
              _c("div", { staticClass: "Time flexRight" }, [
                _vm._v(_vm._s(_vm.orderInfo.createdTime))
              ])
            ]),
            _c("div", { staticClass: "buyOrder flex" }, [
              _c("div", { staticClass: "buy flexleft" }, [_vm._v("买料单数:")]),
              _c("div", { staticClass: "number flexRight" }, [
                _vm._v(_vm._s(_vm.orderInfo.count))
              ])
            ]),
            _c("div", { staticClass: "type flex" }, [
              _c("div", { staticClass: "orderType flexleft" }, [
                _vm._v("种类:")
              ]),
              _c("div", { staticClass: "category flexRight" }, [
                _vm._v(_vm._s(_vm.orderInfo.goods))
              ])
            ]),
            _c("div", { staticClass: "totalWeight flex" }, [
              _c("div", { staticClass: "weight flexleft" }, [_vm._v("总重:")]),
              _c("div", { staticClass: "number flexRight" }, [
                _vm._v(_vm._s(_vm.orderInfo.weight) + "克")
              ])
            ]),
            _c("div", { staticClass: "totalMoney flex" }, [
              _c("div", { staticClass: "money flexleft" }, [_vm._v("总金额:")]),
              _c("div", { staticClass: "number flexRight" }, [
                _vm._v(_vm._s(_vm.orderInfo.money) + "元")
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "know", on: { click: _vm.goSeeSee } }, [
          _vm._v(" 查看结算单 ")
        ]),
        _c("div", { staticClass: "backHome", on: { click: _vm.backHome } }, [
          _vm._v(" 返回首页 ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }