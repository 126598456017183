<template>
   <div class="apply">
    <van-nav-bar
       title="取料申请"
       left-arrow
       safe-area-inset-top
       :border="false"
        @click-left="leftClick"
     />
<!--    <div class="TipsBg">-->
<!--         <div class="tipsTxt">
                <div class="txtBg"></div>
                <div class="txt">
                    <h1>提料申请提交成功！</h1>
                    <p>申请已进入审核(审核时间为10:00-17:00)，</p>
                    <p>提交付款凭证截图可加快审核喔！ <strong class="comfig" @click="goSeeSee">去提交</strong> <span  class="icon"></span></p>
                </div>
        </div> -->
<!--    </div>-->
    <div class="orderInfo">
        <div class="orderbg">
             <div class="orderTop">
                <div class="orderTopText">
                    <h2>提交成功</h2>
                    <div class="auditTime">
                        申请已进入审核(审核时间为10:00-17:00)，提交付款凭证截图可加快审核哦！
                        <span @click="goSeeSee">去上传>></span>
                    </div>
                </div>
             </div>
             <div class="orderBtm">
                <!-- 提料单号 -->
                <div class="oddNumbers flex">
                    <div class="odd flexleft">提料单号</div>
                    <div class="number">{{orderInfo.openNo}}</div>
                </div>
                <!-- 提交时间 -->
                 <div class="oddTime flex">
                    <div class="odd flexleft">提交时间:</div>
                    <div class="Time flexRight">{{orderInfo.createdTime}}</div>
                </div>
                 <div class="buyOrder flex">
                    <div class="buy flexleft">买料单数:</div>
                    <div class="number flexRight">{{orderInfo.count}}</div>
                </div>
                 <div class="type flex">
                    <div class="orderType flexleft">种类:</div>
                    <div class="category flexRight">{{orderInfo.goods}}</div>
                </div>
                <div class="totalWeight flex">
                    <div class="weight flexleft">总重:</div>
                    <div class="number flexRight">{{orderInfo.weight}}克</div>
                </div>              
                 <div class="totalMoney flex">
                    <div class="money flexleft">总金额:</div>
                    <div class="number flexRight">{{orderInfo.money}}元</div>
                </div>
             </div>
        </div>
<!--        <div class="orderTop">
          <img src="@/assets/images/success_black@2x.png" alt="">
          <div class="success">提料申请提交成功！</div>
          <p>申请已进入审核(审核时间为10:00-17:00)，提交付款凭证截图可加快审核喔！ </p>
          <el-button class="btn" @click="goSeeSee">去上传</el-button>
        </div>
        <div class="orderBottom">
          <div class="orderBottomTitle">订单信息</div>
          <div class="orderInfoDetail">
            <div class="flex_between">
              <div>提料单号：</div>
              <div>{{orderInfo.openNo}}</div>
            </div>
            <div class="flex_between">
              <div>提交时间：</div>
              <div>{{orderInfo.createdTime}}</div>
            </div>
            <div class="flex_between">
              <div>买料单数：</div>
              <div>{{orderInfo.count}}</div>
            </div>
            <div class="flex_between">
              <div>种类：</div>
              <div>{{orderInfo.goods}}</div>
            </div>
            <div class="flex_between">
              <div>总重：</div>
              <div>{{orderInfo.weight}}</div>
            </div>
            <div class="flex_between">
              <div>总金额：</div>
              <div>{{orderInfo.money}}</div>
            </div>
          </div>
        </div>-->
        <div class="know" @click="goSeeSee">
            查看结算单
        </div>
        <div class="backHome" @click="backHome">
            返回首页
        </div>
    </div>
   </div>
</template>

<script>
import headbox from "@/components/head.vue";
import { mapState } from 'vuex'
export default {
    components:{
        headbox
    },
      computed: {
    ...mapState(['orderInfo'])
    },
    data() {
        return {
        }
    },
    methods:{
        goSeeSee(){
            this.$router.push({
                name:'purchaseSettlement',
                query:{openId:this.orderInfo.openId}
            })
        },
        backHome(){
            this.leftClick()
        },
        leftClick(){
            this.$router.push({
                name:'person'
            })
        }
    },
    created(){
        // console.log(this.orderInfo);

    }
}
</script>

<style lang="scss" scoped="true">
.apply {
  background: #F1F3F7;
  height: 100%;
}
    .TipsBg{
        width: 100%;
        height: 400px;
        padding: 30px;
        background: url("~@/assets/images/bg@2x.png") no-repeat;
        background-size:100% 100%;
        .tipsTxt{
            width: 100%;
            height: 158px;
            margin-top: 100px;
            display: flex;
            .txtBg{
                width: 190px;
                height: 158px;
                background: url("~@/assets/images/tick.png") no-repeat;
                background-size: 100% 100%;
            }
            .txt{
                width: 100%;
                padding: 8px 10px 0 30px;
                color: #fff;
                position: relative;
                h1{
                    font-size: 36px;
                    font-weight: 400;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 16px;
                }
                .comfig{
                    font-size: 18px;
                }
                .icon{
                    position: relative;
                    top: 2px;
                    width: 20px;
                    height: 16px;
                    background: url("~@/assets/images/＞.png");
                    background-size: 100% 100%;
                }
            }
        }
    }
    .orderInfo{
        //position: relative;
        top: -360px;
        width: 100%;
        height: 620px;
        padding: 0 20px;
        margin: 0 auto;
        .orderbg{
            width: 100%;
            height: 940px;
            background: $YellowTiliaoSuccessBg;
            background-size: 100% 100%;
            position: relative;
            top: -10px;
            padding: 0px 50px;
            .orderTop{
                width: 100%;
                height: 50%;
                position: relative;
                .orderTopText{
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 70px;
                    h2{
                        font-size: 36px;
                        text-align: center;
                        font-weight: 500;
                        color: #242424;
                    }
                    .auditTime{
                        margin-top: 20px;
                        padding: 0 20px;
                        color: #999999;
                        font-size: 28px;
                      span {
                        color: $YellowFontColor;
                      }
                    }

                }
            }
            .orderBtm{
                width: 100%;
                height: 50%;
                padding: 40px 20px;
                font-size: 28px;
            }
            .zhanghu{
                margin-top: 50px;
                margin-bottom: 20px;
                width: 200px !important;
                text-align: left !important;
            }
        }
      .orderTop{
        margin: 24px auto;
        //background: #ffffff;
        text-align: center;
        padding: 32px 32px 48px;
        img {
          width: 80px;
          height: 80px;
        }
        .success {
          font-weight: 600;
          color: #39394D;
          font-size: 36px;
          margin-top: 16px;
        }
        p {
          color: #8F8F9F;
          font-size: 28px;
          margin-top: 32px;
        }
        .btn {
          width: 180px;
          height: 72px;
          background: #39394d;
          border-radius: 40px;
          color: #f6d0ab;
          font-size: 28px;
          font-weight: 600;
          margin-top: 32px;
        }
      }
      .orderBottom{
        background: #ffffff;
        margin: 24px;
        padding: 32px 24px;
        .orderBottomTitle {
          color: #39394D;
          font-size: 32px;
          font-weight: 600;
        }
        .orderInfoDetail {
          margin-top: 24px;
          .flex_between {
            display: flex;
            justify-content: space-between;
            color: #8F8F9F;
            font-size: 28px;
            margin-bottom: 16px;
          }
        }
      }
        .know{
            width: 98%;
            height: 80px;
            background-color: $YellowFontColor;
            margin: 20px auto;
            text-align: center;
            line-height: 80px;
            color: $YellowHeadBackgroundColor;
            font-size: 28px;
            border-radius: 50px;
        }
        .backHome{
            width: 98%;
            height: 80px;
            //background-color: #39394D;
            border: 1px solid $YellowFontColor;
            margin: 20px auto;
            text-align: center;
            line-height: 80px;
            color: $YellowFontColor;
            font-size: 28px;
            border-radius: 50px;
        }
    }
    .goSeeSee{
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #CCCCCC;
        text-align: center;
        position: relative;
        font-size: 28px;
       i{
           position: absolute;
           top: 2px;
           width: 100px;
           height: 30px;
           background: url("~@/assets/images/look.png") no-repeat;
           background-size: 100% 100%;
       }
    }
    .flex{
        display: flex;
        margin-bottom: 22px;
    }
    .flexleft{
        color: #999999;
        text-align: left;
        width: 120px;
        margin-right: 10px;
        font-size: 28px;
        margin-right: 60px;
    }
    .flexRight{
        font-size: 28px;
        font-weight: 400;
        color: #242424;
    }
    ::v-deep{
     .van-nav-bar{
        height: 100px;
        background-color: $YellowHeadBackgroundColor;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
        .van-nav-bar__title{
          color: $YellowHeadColor;
            font-size: 40px;
        }
        .van-icon{
            font-size: 40px;
            color: $YellowHeadColor;
        }
        }
     }
}
</style>